<template>
  <v-hover @input="$store.state.finemailer.elementHover = $event" v-model="hover">
    <div @click="selectElement" :style="elementStyle"  class="d-flex"
         style="position: relative;"
    >

      <email-edit-side-menu
          custom-style="position: absolute; top: -48px; z-index: 1;"
          v-if="isSelected"
          :items="editItemsSelect"
          horizontal
          @action="elementEditAction"
      />


      <div style="width : 100%"
           :id="`email_element_${element.uuid}`"
           class="d-flex"
           :class="[
            {'justify-center' : !['text','heading'].includes(element.type) && element.config.align === 'center'},
            {'justify-end'    : !['text','heading'].includes(element.type) && element.config.align === 'right'}
         ]"
      >
        <email-text-element-editor
            style="width: 100%"
            @edit="editElement"
            v-if="['heading' , 'text'].includes(element.type)"
            :element="element"
            :key="element.uuid + index"
        />

        <email-image-element-editor
            v-if="element.type === 'image'"
            :element="element"
            :key="element.uuid + index"
        />

        <email-button-element-editor
            v-if="element.type === 'button'"
            :element="element"
            :key="element.uuid + index"
        />

        <email-line-element-editor
            v-if="element.type === 'line'"
            :element="element"
            :key="element.uuid + index + 'line'"

        />
        <email-file-element-editor
            v-if="element.type === 'file'"
            :element="element"
            :key="element.uuid + index"
        />

      </div>



      <editor-add-block-button
          @click="$emit('add',{type : $event , position : (index + 1) + 1 })"
          @dropdown="addElementDropdown = $event"
          :display="displayAddButton"
          :items="EMAIL_ELEMENTS_SELECT"
          small
          bottom="-13"
      />

    </div>
  </v-hover>

</template>

<script>
import {mapActions, mapState} from "vuex";

import emailEditSideMenu
  from "@/modules/finemailer/components/emailConstructor/editor/UI/emailEditSideMenu";
import emailImageElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/elements/emailImageElementEditor";
import emailTextElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/elements/emailTextElementEditor";
import emailButtonElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/elements/emailButtonElementEditor";
import emailLineElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/elements/emailLineElementEditor";
import emailFileElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/elements/emailFileElementEditor";

import editorAddBlockButton
  from "@/modules/finemailer/components/emailConstructor/editor/UI/editorAddBlockButton";
import finemailer from "@/modules/finemailer/mixins/finemailer";

export default {
  name: "emailElementEditor",
  mixins : [finemailer],
  components : {
    emailImageElementEditor,
    emailTextElementEditor,
    emailButtonElementEditor,
    emailLineElementEditor,
    editorAddBlockButton,
    emailFileElementEditor,
    emailEditSideMenu
  },
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    },
    index : {
      type : Number
    },
  },
  data() {
    return {
      hover : false,
      addElementDropdown : false,
    }
  },
  computed : {
    ...mapState('finemailer' , [
        'elementEditTrigger',
        'selectedElement',
        'navigation'
    ]),
    editItemsSelect() {
      let items = this.COPY(this.EMAIL_BLOCK_EDIT_ACTIONS_SELECT)
      items.unshift({ divider : true})

      items.unshift({ icon : `mdi-format-align-${this.element.config.align || 'left'}` , value : 'align' , children : [
          { icon : 'mdi-format-align-left'  , value : 'left' },
          { icon : 'mdi-format-align-center' , value : 'center' },
          { icon : 'mdi-format-align-right' , value : 'right' }
        ]}
      )
      items.unshift({ text : 'Заголовок', currentValue : this.element.config.font_style , value : 'font_style' , fontStyle : true, children : [
          { text : this.$t('avalon.color_styles.p') , value : 'p' },
          { text : this.$t('avalon.color_styles.h6') , value : 'h6' },
          { text : this.$t('avalon.color_styles.h1') , value : 'h1' },
          { text : this.$t('avalon.color_styles.h2') , value : 'h2' },
          { text : this.$t('avalon.color_styles.h3') , value : 'h3' },
          { text : this.$t('avalon.color_styles.h4') , value : 'h4' },
          { text : this.$t('avalon.color_styles.h5') , value : 'h5' },
        ]}
      )

      return items
    },
    isSelected() {
      return this.selectedElement.uuid === this.element.uuid && this.navigation === 'element'
    },
    displayHover() {
      return this.hover ||  this.addElementDropdown || this.isSelected
    },
    displayAddButton() {
      return this.hover ||  this.addElementDropdown
    },
    elementStyle() {
      let style = ''

      if ( this.element.config.margin_top) {
        style += `margin-top : ${this.element.config.margin_top}px;`
      }

      if ( this.element.config.margin_bottom) {
        style += `margin-bottom : ${this.element.config.margin_bottom}px;`
      }

      if ( this.displayHover) {
        style += `box-shadow: inset 0px 0px 0 2px ${this.wsATTENTION};`
       ;
      }

      return style
    }
  },
  watch : {
    elementEditTrigger() {

      if ( this.selectedElement.uuid !== this.element.uuid ) {
        return
      }
      this.editElementConfig()
    }
  },
  methods : {
    ...mapActions('finemailer' , [
        'ADD_EDIT_DESIGN_ELEMENT',
        'DELETE_DESIGN_ELEMENT',
        'MOVE_DESIGN_ELEMENT'
    ]),

    async elementEditAction(data) {
      this.notify(data , 'warning')
      const split = data.split('@')
      const action = split[0]
      const value = split[1] ?? null

      switch (action) {
        case 'delete' : this.deleteElement() ; break;
        case 'move_up' : this.moveElement(-1) ; break;
        case 'move_down' : this.moveElement(1) ; break;
        case 'duplicate' : this.duplicateElement() ; break;
        case 'align' :
          this.$store.state.finemailer.selectedElement.config.align = value;
          this.editElementConfig();
          break;
        case 'font_style' :
          this.$store.state.finemailer.selectedElement.config.font_style = value;
          this.editElementConfig();
          break;
      }


    },
    duplicateElement() {
      this.$emit('duplicate',{element : this.element , position : (this.index + 1) + 1 })
    },
    async moveElement(direction) {
      let data = {
        uuid : this.element.uuid,
        direction : direction
      }
      this.$emit('move' , {uuid : this.element.uuid , direction : direction })

      let result = await this.MOVE_DESIGN_ELEMENT(data)
      if (!result) {
        return this.ERROR()
      }

    },
    async deleteElement() {
      this.$emit('delete' , this.element.uuid)
      let result = await this.DELETE_DESIGN_ELEMENT(this.element.uuid)
      if (!result) {
        return this.ERROR()
      }

    },
    async editElement(element) {
      if ( !element) {
        element = this.COPY(this.element)
      }
      this.$emit('edit' , this.COPY(element))
      let result = await this.ADD_EDIT_DESIGN_ELEMENT(element)
      if ( !result ) {
        return this.ERROR()
      }

    },
    async editElementConfig() {
      this.$emit('edit' , this.COPY(this.selectedElement))
      let result = await this.ADD_EDIT_DESIGN_ELEMENT(this.selectedElement)
      if ( !result ) {
        return this.ERROR()
      }

    },

    selectElement() {
      setTimeout(()=> {
        this.$store.state.finemailer.selectedElement = this.COPY(this.element)
        this.$store.state.finemailer.navigation = 'element'
      },2)

    }
  }
}
</script>

<style scoped>

</style>