<template>
  <div :style="buttonStyle" class="wsRoundedLight d-flex"
       :class="[{'justify-center' : element.config.full_width && element.config.style !== 'text'}]"

  >
    <span ref="mirror" :style="textFieldStyle" class="mirror">{{ element.content }}</span>

    <textarea
        v-model="element.content"
        @input="adjustTextareaWidth"
        @change="editText"
        @keydown.enter="editText"
        ref="text"
        type="text"
        :placeholder="$t('EnterText')"
        :style="textFieldStyle"
        style="resize: none; outline: none;"
        rows="1"
        class="text-no-wrap shorten-text"
    />

  </div>


</template>

<script>
import finemailer from "@/modules/finemailer/mixins/finemailer";

export default {
  name: "emailTextElementEditor",
  mixins : [finemailer],
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    }
  },
  computed : {
    textFieldStyle() {
      let style = ''

      if ( this.element.config.style === 'primary') {
        style += `color : ${this.EMAIL_CONFIG_COLOR('button_text')};`
        style += `text-align: center;`
      } else if (this.element.config.style === 'text') {
        style += `color : ${this.EMAIL_CONFIG_COLOR('link')};`
      }  else {
        style += `color : ${this.EMAIL_CONFIG_COLOR('secondary_button_text')};`
        style += `text-align: center;`
      }

      style += `font-size: 14px;`
      style += `font-weight: 500 !important ;`
      style += `padding: 0 !important ;`

      return style
    },

    buttonStyle() {
      let style = ''

      if ( this.element.config.style === 'primary') {
        style += `background-color: ${ this.EMAIL_CONFIG_COLOR('button_bg')}; `
      } else  if ( this.element.config.style === 'secondary') {
        style += `border: 1px solid ${this.EMAIL_CONFIG_COLOR('secondary_button_bg')}; `
      }

      if (this.element.config.style !== 'text') {
        style += `padding : 8px 24px;`
      }

      if ( this.element.config.full_width && this.element.config.style !== 'text') {
        style += `width : 100%;`
      }

      return style
    },
    config() {
      return this.element.config
    }
  },
  watch : {
    EMAIL_CONFIG : {
      handler() {
        this.adjustTextareaWidth()
      },
      deep : true
    }
  },
  methods : {
    adjustTextareaWidth($event) {
      const input = this.$refs.text;
      const mirror = this.$refs.mirror;

      // Update the mirror span's content with the input's value, replacing spaces with non-breaking spaces to preserve whitespace
      mirror.textContent = this.element.content.replace(/ /g, '\u00a0');

      let width = mirror.offsetWidth ;

      if ( width < 120) {
        input.style.width = `${120}px`;
      }
      else if ( width < (700 - 48)) {
        input.style.width = `${width}px`;
      } else {
        input.style.width = `${700-48}px`;
        $event.preventDefault()
      }
    },

    adjustTextareaHeight() {
      const input = this.$refs.text;
      input.style.height = "auto";
      if ( this.element.content ) {
        input.style.height = input.scrollHeight + "px";
      }
    },
    editText($event) {
      this.$store.state.finemailer.selectedElement.content = $event.target.value
      this.$store.state.finemailer.elementEditTrigger++
    },
    handleInput(input) {
      this.notify(input.target.innerText)
    }
  },

  mounted() {
    setTimeout(this.adjustTextareaHeight , 1)
  }
}
</script>

<style scoped>
.mirror {
  visibility: hidden;
  white-space: pre;
  position: absolute;
  top: 0;
  left: 0;
}
.v-input__slot {
  padding : 0 !important
}
</style>