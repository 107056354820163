<template>
<v-sheet
    :width="!EMAIL_MOBILE_VIEW ? 700 : 320"
    class="mx-auto d-flex flex-column "
    :style="emailBodyStyle"
    min-height="100%"
    style="box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15); position: relative"
    :color="EMAIL_CONFIG_COLOR('email')"
>


  <email-header-editor />


  <email-block-editor
      v-for="(block,index) in entity.blocks" :key="block"
      :uuid="block"
      :index="index"
      :is-last="index === entity.blocks.length - 1"
      @add="addBlock($event, index + 2)"
      @delete="deleteBlock(block, index)"
      @move="moveBlock(block, $event , index)"
  />


  <div  v-if="entity.blocks.length > 0" class="py-6">
    <ws-button  @click="addBlock({block : EMAIL_NEW_BLOCK_DEFAULT(entity.blocks.length > 0)}  , entity.blocks.length + 1 )" block label="AddBlock" left-icon="mdi-plus"/>
  </div>

  <div class="flex-grow-1 d-flex align-center justify-center">
    <ws-blank-page
        v-if="entity.blocks.length === 0"
        text="AddFirstBlock"
        icon="mdi-widgets"
        button-text="AddBlock"
        :button-action="() => addBlock({block : EMAIL_NEW_BLOCK_DEFAULT(entity.blocks.length > 0)} , entity.blocks.length + 1)"
        no-fill-height
    />
  </div>

  <email-footer-editor />

</v-sheet>
</template>

<script>
import emailHeaderEditor
  from "@/modules/finemailer/components/emailConstructor/editor/emailHeaderEditor";
import emailFooterEditor
  from "@/modules/finemailer/components/emailConstructor/editor/emailFooterEditor";
import wsBlankPage from "@/components/UI/wsBlankPage";
import emailBlockEditor from "@/modules/finemailer/components/emailConstructor/editor/emailBlockEditor";
import {mapActions} from "vuex";
import finemailer from "@/modules/finemailer/mixins/finemailer";


export default {
  name: "EmailEditor",
  mixins : [finemailer],
  components : {
    emailHeaderEditor,
    emailBlockEditor,
    emailFooterEditor,
    wsBlankPage
  },
  props : {
    uuid : {
      type : String,
    }
  },
  data() {
    return {
      entity : {
        blocks : []
      },
      mobile : false
    }
  },
  computed : {
    emailBodyStyle() {
      let style = ''
      if ( this.EMAIL_CONFIG.body_padding_top) {
        style += `padding-top: ${this.EMAIL_CONFIG.body_padding_top}px;`
      }
      if ( this.EMAIL_CONFIG.body_padding_bottom) {
        style += `padding-bottom: ${this.EMAIL_CONFIG.body_padding_bottom}px;`
      }
      if ( this.EMAIL_CONFIG.body_padding_left) {
        style += `padding-left: ${this.EMAIL_CONFIG.body_padding_left}px;`
      }
      if ( this.EMAIL_CONFIG.body_padding_right) {
        style += `padding-right: ${this.EMAIL_CONFIG.body_padding_right}px;`
      }

      if ( this.EMAIL_CONFIG.body_margin_top) {
        style += `margin-top: ${this.EMAIL_CONFIG.body_margin_top}px;`
      }

      if ( this.EMAIL_CONFIG.body_margin_bottom) {
        style += `margin-bottom: ${this.EMAIL_CONFIG.body_margin_bottom}px;`
      }

      return style
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'GET_NEWSLETTER_DESIGN',
      'ADD_EDIT_DESIGN_BLOCK',
      'MOVE_DESIGN_BLOCK',
      'DELETE_NEWSLETTER_DESIGN_BLOCK'
    ]),

    async deleteBlock(uuid,index) {

      let result = this.DELETE_NEWSLETTER_DESIGN_BLOCK(uuid)
      if ( !result ) {
        return this.ERROR()
      }
      this.entity.blocks.splice(index,1)
      this.entity = this.COPY(this.entity)
    },
    async moveBlock(uuid , direction , index) {

      this.entity.blocks.splice(index, 1)
      this.entity.blocks.splice(index + direction, 0 ,uuid)

      let data = {
        uuid : uuid,
        direction : direction
      }

      let result = await this.MOVE_DESIGN_BLOCK(data)
      if ( !result ) {
        return this.ERROR()
      }

    },
    async addBlock(event, orderNumber ) {
      let { block } = event
      let position = orderNumber
      block.newsletter_design_id = this.uuid
      block.order_number = position

      let result = await this.ADD_EDIT_DESIGN_BLOCK(block)
      if ( !result ) {
        return this.ERROR()
      }
      this.$store.state.finemailer.newBlockId = result.uuid
      this.entity.blocks.splice(orderNumber - 1 , 0 , result.uuid)
      this.entity = this.COPY(this.entity)
    },

    async initPage() {
      let result = await this.GET_NEWSLETTER_DESIGN(this.uuid)
      if (!result) {
        this.ERROR()
      }
      this.entity = result
      this.$store.state.businessConfig = result.business_config
      this.$store.state.finemailer.selectedNewsletter = result
      this.$store.state.finemailer.newsletterConfig = result.config
      this.PREPARE_EMAIL_CONFIG()
      this.$emit('update-design' , result)

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>