<template>
<v-row class="fill-height" no-gutters>
  <v-col cols="6" xl="4">
    <v-sheet class="fill-height">
      <div class="px-8 py-6 d-flex justify-space-between">
        <ws-logo  :title="!SM ? `mailer.wizard.${newsletterType || 'auto'}.title` : null"/>
      </div>



      <div class="px-8">
        <email-settings-accordion
            :newsletter="newsletter"
        />
      </div>

    </v-sheet>

  </v-col>
  <v-col cols="6" xl="8" class="fill-height">
    <div class="d-flex flex-column fill-height">
      <div  class="d-flex justify-end pr-8 pt-6 pb-3" >
        <v-btn @click="$emit('close')" icon :color="wsACCENT">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="flex-grow-1 overflow-hidden"  :color="wsATTENTION" >
        <email-viewer
            :uuid="newsletter.newsletter_design_id"
            preview
            half-rounded
            width="700"
        />
      </div>

    </div>





  </v-col>
</v-row>
</template>

<script>
import finemailer from "@/modules/finemailer/mixins/finemailer";
import emailSettingsAccordion
  from "@/modules/finemailer/components/newsletterWizard/emailSettingsAccordion/emailSettingsAccordion";
import EmailViewer from "@/modules/finemailer/components/emailConstructor/viewer/EmailViewer.vue";

export default {
  name: "newsletterWizzardSettings",
  components : {
    EmailViewer,
    emailSettingsAccordion
  },
  mixins : [finemailer],
  props : {
    newsletter : {
      type : Object,
      default() { return {} }
    },
  },
  methods : {

  }

}
</script>

<style scoped>

</style>